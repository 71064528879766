/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css'); */

.footer {
    width: 100vw;
    background-color: var(--black);
    margin: 0;
    position: sticky;
    padding: 2rem
}

.top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 1rem;
    flex-wrap: wrap;
    padding: 2rem 2rem;
    text-align: start;
}

.top-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.top-image img {
    width: 150px;
    border-radius: 10%;
}

.top-image h2 {
    color: bisque;
    opacity: 0.7;
}

.top-image p {
    font-size: 16px;
    color: antiquewhite;
    opacity: 0.5;
}

.top .item {
    text-align: left;
    width: 15vw;
    height: 20vh;
}

.top h1 {
    color: var(--main-color);
    font-size: 20px;
    font-weight: 700;
    margin-left: 1rem;
}

.top p {
    color: white;
    font-size: 18px;
    margin-left: 1rem;
    /* white-space: nowrap;        */
}

.top i {
    color: white;
    font-size: 2rem;
    margin-left: 0.1rem;
}

.top a {
    color: white;
    font-size: 18px;
    margin-left: 1rem;
}

.top a:hover {
    color: var(--main-color);
    background-color: transparent !important;
    ;
}

.top i:hover {
    cursor: pointer;
    color: var(--main-color);
    background-color: transparent !important;
    ;
}

.bottom {
    display: flex;
    align-self: start;
    justify-content: space-between;
    text-align: start;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 2rem;
}


.bottom .item {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 20vw;
    height: 25vh;
}

.bottom h1 {
    color: var(--main-color);
    font-size: 20px;
    font-weight: 700;
    padding: 1rem 0 .8rem 0;
    margin-left: 1rem;

}

.bottom div a {
    text-decoration: none;
    color: white;
    padding-bottom: .4rem;
    font-size: 18px;
    margin-left: 1rem;
}

.bottom div a:hover {
    color: var(--main-color);
    background-color: transparent !important;
    ;
}

@media screen and (max-width: 850px) {
    .footer i {
        margin: 1rem 1rem 0 0;
    }

    .top {
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    .top-image img {
        width: 30%
    }

    .top .item {
        width: 50%;
        height: 30%;
    }

    .bottom {
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .bottom .item {
        width: 50%;
        height: 30%;
    }

    .bottom .item a:hover {
        cursor: pointer;
    }

    .top-image h2 {
        text-align: center;
    }
    .top-image p {
        text-align: center;
    }
}