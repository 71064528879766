:root {
    --main-color: brown;
    --base-color: #021e44;
    --brown: brown;
    --red: red;
    --black: #000;
    --light-black: #aaa;
    --green: #25A560;
    --yellow: #f8ed4d;
    --gold: #f59701;
    --orange: #f39c12;
    --light-color: #888;
    --light-bg: rgb(255, 255, 255);
    --light-blue: rgba(172, 225, 255, 0.945);
    --blue: #001a9b;
    --blue-black: #000b41;
    --white: white;
    --smoke: whitesmoke;
    --plain-color: rgba(217, 221, 146, 0.2);
    --border: 2px solid var(--light-color);
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
}

.confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    --color: hsl(var(--primary-dark-hsl), .7);
    background-color: var(--white);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 20px 20px;
    min-width: 400px;
    border-radius: 10px;
}

.view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: hsl(var(--primary-dark-hsl), .7);
    background-color: var(--white);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 10px 20px;
    min-width: 600px;
    border-radius: 10px;
}

.confirm-container h3 {
    padding: 0 10px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: var(--main-color);
}

.confirm-container p {
    padding: 0 10px;
    margin-bottom: 20px;
    font-size: 16px;
}

.confirm-buttons>button {
    font-weight: 600;
    width: 100px;
}


.name-logo {
    display: flex;
    text-align: start;
    justify-content: flex-start;
    position: relative;
    padding: 1rem 1.5rem;
    /* margin-right: auto; */
    /* margin-left: 0.2rem; */
}

.name-logo>h4 {
    font-size: 24px;
    font-weight: bold;
    color: var(--yellow);
}

.name-logo img {
    width: 40px;
    border-radius: 50%;
}


.custom-ui {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: hsl(var(--primary-dark-hsl), .7);
    background-color: var(--white);
    box-shadow: 0 0 15px 0 var(--black);
    padding: 10px 20px;
    min-width: 600px;
    border-radius: 10px;
}

form label span {
    color: var(--red);
}

form label p {
    margin-top: 2pxhdh;
    color: var(--black);
}

.photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.photo img {
    width: 10%;
    border-radius: 10px;
}

.driver-code {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
}

.driver-code h1 {
    font-size: 18px;
    font-weight: bold;
    color: var(--main-color);
}

.driver-code h2 {
    font-size: 18px;
    font-weight: bold;
    color: brown;
}

.driver-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: 10px;
}

.driver-photo img {
    width: 10%;
    border-radius: 10px;
}

.registration-ui {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--black);
    box-shadow: 0 0 15px 0 var(--black);
    padding: 10px 20px;
    width: 100%;
    min-width: 1200px;
    height: 70vh;
    border-radius: 10px;
    margin-top: 180px;
    overflow-x: hidden;
    overflow-y: auto;
}

.input-info {
    margin: 0;
    color: var(--red);
    font-size: 12px;
    font-weight: bold;
}


/* NEW */
.remove-button {
    display: none;
}

.offscreen {
    position: absolute;
    left: -10000px;
}

.split-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    gap: 1rem;
}

.flex-col {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.35rem;
    width: 100%;
}

.button-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
}

.location-view {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    padding: 20px;
}

.a-link {
    margin: 0;
    color: var(--blue-black);
    font-size: 12px;
    font-weight: bold;
}

.a-link:hover,
.a-link:active {
    color: var(--green)
}

.rate-info {
    color: var(--red)
}

.gps-location {
    display: flex;
    gap: 2rem;
}

.main-content {
    min-height: 90vh;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.payment-directive {
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-directive p {
    font-size: 16px;
}

.wrapper-container {
    padding: 2rem 2rem;
    border-radius: 10px;
    /* background-color: var(--main-color); */
}

@media screen and (max-width: 950px) {
    .wrapper-container {
        padding: 2rem;
    }
        .driver-photo img {
            width: 30%;
        }
}




.checkbox-wrapper {
    border-bottom: 1px solid #c6c6c6;
    margin-bottom: 20px;
}

.checkbox-wrapper:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.checkbox-wrapper label {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    padding: 20px;
}

label+p {
    color: grey;
}

/* Customizing the checkbox */

.checkbox-wrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 0.15em solid #007a7e;
    outline: none;
    cursor: pointer;
}

input.checked {
    background-color: #007a7e;
    position: relative;
}

input.checked::before {
    content: "\2714";
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
}

/* input:checked {
  background-color: #007a7e;
  position: relative;
}

input:checked::before {
  content: "\2714";
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
} */

.checkbox-wrapper input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:disabled+span {
    color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:focus {
    box-shadow: 0 0 20px #007a7e;
}