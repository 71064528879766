:root {
    --primary-light-hsl: 200, 100%, 91%;
    --primary-hsl: 200, 100%, 50%;
    --primary-dark-hsl: 200, 100%, 6%;
    --success-hsl: 100, 60%, 50%;
    --error-hsl: 0, 60%, 50%;
}

.full-screen-container {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    object-fit: contain;
}

.login-container {
    --color: hsl(var(--primary-dark-hsl), .7);
    background-color: var(--main-color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 10px 10px;
    width: 80%;
    max-width: 400px;
    border-radius: 10px;
}

.login-title {
    margin: 0;
    color: var(--white);
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}


.form {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 0.75rem;
    border-radius: 10px;
}


.input-group {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.input-group label {
    color: var(--blue-black);
    font-weight: lighter;
}

.input-group input {
    font-size: 16px;
    padding: .4em;
    max-width: 400px;
    height: 50px;
    border: none;
    outline: none;
    border-radius: .25em;
    background-color: var(--white);
}

.input-group.success input {
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.input-group.error input {
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.input-group input:focus {
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.input-group .msg {
    display: none;
    font-size: .75rem;
}

.input-group.success .msg,
.input-group.error .msg {
    display: block;
}

.input-group.error .msg {
    color: hsl(var(--error-hsl))
}

.input-group.success .msg {
    color: hsl(var(--success-hsl))
}

.login-button {
    font-size: 16px;
    max-width: 400px;
    height: 40px;
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25em;
    outline: none;
    cursor: pointer;
}

.login-button:hover,
.login-button:focus {
    background-color: #000;
    color: #fff;
}

.instructions {
    font-size: 14px;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
    text-align: center;
}

.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: var(--white);
    margin-left: 0.25rem;
}

.invalid {
    color: var(--red);
    margin-left: 0.25rem;
}

.invalid .fa-times {
    color: var(--red);
    margin-left: 0.25rem;
    font-size: 12px;
}

.errmsg {
    background-color: var(--brown);
    color: var(--white);
    text-align: center;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0;
    font-size: 18px;
    border-radius: 0.5rem;
}

.line {
    display: inline-block;
}

.flexGrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.persistCheck {
    font-size: 1.5rem;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end
}

.persistCheck label {
    margin: 0;
    padding-left: 5px;
    color: var(--white);
}

.login-container [type='checkbox'] {
    height: 20px;
    width: 20px;
    margin: 0 5px 2px 2px;
}

/* Logo Login */
.login-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-img {
    width: 90px;
    height: 90px;
    position: relative;
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
    border: 10px double rgba(0, 0, 0, 0.959);
    border-radius: 50%;
}