.driver-card-container {
    display: flex;
    flex-flow: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    /*hide scrollbarin Chrome, Safari, and Edge*/
    -ms-overflow-style: none;
    /*hide scrollbarin IE and Edge*/
}

.driver-card-container::-webkit-scrollbar {
    display: none;
    /*hide scrollbarin Chrome, Safari, and Edge*/
}

.driver-card-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    gap: 2rem;
    background-color: var(white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 16px;
    text-align: center;
}

.driver-card-left img {
    width: 70%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 10%;
}

.driver-card-left h2 {
    font-size: 24px;
    font-weight: 600;
}

.driver-card-left p {
    font-size: 20px;
    font-weight: 500;
}


.driver-card-right {
    width: 100%;
    background-color: var(white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 16px;
}

.driver-card-right h2 {
    font-size: 24px;
    font-weight: 600;
    color: var(--brown)
}

.driver-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
}
.driver-details h3{
   font-size: 20px;
   font-weight: 500;
}


@media screen and (max-width:600px) {
    .driver-card {
        width: 100%;
    }

    .driver-card-container {
        flex-direction: column;
    }
        .driver-details {
           
            grid-template-columns: repeat(1, 1fr);
           margin: 0 auto;
           
        }
}