.flyer-item {
    display: flex;
    align-flyer-items: center;
    justify-content: center;
    /* min-height: 10rem; */
    /* min-width: 30rem; */
    padding: 5px;
    font-size: 40px;
    font-weight: bold;
}

.flyer-item img {
    width: 70%;
    /* height: 50%; */
    border-radius: 1rem;
    pointer-events: none;
    padding: 5%;
}

.inner-carousel {
    display: flex;
    background: rgb(255, 50, 50);
    height: 23rem;
}

.carousel {
    cursor: grab;
    overflow: hidden;
    background: #f7f9fc;
    height: auto;
    border-radius: 10px;
}

@media screen and (max-width: 1200px) {
    
.flyer-item {
    font-size: 20px;
}   
}