.info .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 6px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
}
.info img {
  width: 65px;
  height: 65px;
  margin: auto;
}

.info .box h4 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 10px;
}

.info .box h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}

.info .box p {
  font-size: 24px;
  font-weight: 700;
  margin-top: 10px;
  color:var(--main-color)
}