.hero {
  background-image: url("/public//images//banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
}

.hero .container {
  padding-top: 5%;
}


.hero h1 {
  color: #f59701;
  font-size: 60px;
  text-align: center;
  margin: auto;
  font-weight: bold;
  text-transform: capitalize;
  padding-bottom: 20px;
}

.hero p {
  font-size: 30px;
  color: #fff;
  opacity: 0.8;
  padding-bottom: 20px;
}

.hero .register {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
}

@media screen and (max-width: 800px) {
  .hero h1 {
    font-size: 30px;
    margin: auto;
  }

  .hero p {
    font-size: 15px;
  }

  .hero .register {
    flex-direction: column;
  }
}