/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"); */

.container {
	max-width: 80%;
	margin: auto;
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3rem;
	border-radius: 10px;
}

a {
	text-decoration: none;
	/* color: #000; */
	/* text-transform: capitalize; */
}

ul {
	list-style-type: none;
}

button {
	border-radius: 5px;
	padding: 17px 30px;
	background: #27ae60;
	border: none;
	color: #fff;
	cursor: pointer;
	font-weight: bold;
}

.button {
	border-radius: 5px;
	padding: 10px;
	border: none;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
	width: 10%;
}

button i {
	margin-right: 5px;
}

.hero-heading {
	text-align: center;
	width: 60%;
	margin: auto;
}

.hero-heading h1 {
	color: var(--blue-black);
	font-weight: 600;
	text-transform: capitalize;
	font-size: 35px;
}

.hero-heading p {
	color: #72809d;
	font-size: 24px;
}

textarea,
input {
	border: none;
	outline: none;
	background: none;
}

.background {
	padding: 80px 0;
	position: relative;
	background: #f7f9fc;
}

.grid5 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 30px;
}

.terms-grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 30px;
}

label {
	color: var(--black);
	font-size: 16px;
}

.mtop {
	margin-top: 50px;
}

h4 {
	font-size: 24px;
	font-weight: 700;
	margin-top: 10px;
}

.shadow {
	box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}

.shadow h4 {
	color: var(--white);
}

.padding {
	padding: 80px 0;
}

.grid3 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
}

img {
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.btn2 {
	border-radius: 50px;
	font-size: 20px;
}

.grid4 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
}

.btn3 {
	background: #ff6922;
	border-radius: 50px;
}

.btn4 {
	background-color: #000;
	color: #fff;
	white-space: nowrap;
}

.btn5 {
	border-radius: 50px;
	padding: 10px 40px;
	color: #27ae60;
	font-size: 20px;
	font-weight: 400;
	border: 5px solid #27ae601f;
	background: #fff;
	width: 10%;
}

.btn6 {
	border-radius: 50px;
	padding: 10px 40px;
	color: var(--white);
	font-size: 20px;
	font-weight: 400;
	border: 5px solid #27ae601f;
	background: var(--main-color);
	width: 20%;
	white-space: nowrap;
}

.back {
	height: 40vh;
	position: relative;
}

.back img {
	height: 40vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	object-fit: cover;
}

.back::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 40vh;
	background: rgba(17, 40, 72, 0.629);
	z-index: -1;
}

.back .container {
	color: #fff;
	padding: 60px 0;
}

.back h1 {
	font-size: 40px;
	font-weight: 500;
}

.mb {
	margin-bottom: 80px;
}

.navigation-items {
	color: var(--white);
	font-size: 20px;
}


.user-logout {
	position: absolute;
	right: 20px;
	color: var(--yellow);
	font-size: 16px;
}


.toast-message {
	background: var(--white);
	color: black;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 20px;
	width: 34vw;
	max-width: 400px;
}

.left-navbar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.company-logo {
	width: 100px;
}

.company-logo img {
	/* width: 100%; */
	border: 10px double rgb(9, 1, 43);
	border-radius: 30%;
}

.company-name {
	display: flex;
	flex-direction: column;
}

.company-name h2 {
	color: var(--white);
	font-size: 35px;
	font-weight: 700;
	margin-left: 20px;
}

.company-name p {
	color: #f59701;
	font-size: 20px;
	text-align: center;
	font-weight: 700;
	margin-left: 20px;
	text-transform: uppercase;
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	row-gap: 2rem;
}

.loader p {
	font-size: 18px;
	font-weight: 600;
}

.toggle-bar {
	position: relative;
	top: 10px;
	width: 40px;
	height: 40px;
}

.toggle-bar .fa-bars {
	display: none
}


.table>tbody>tr>td>div>a {
	color: #266928;
	font-size: 16px;
}

.table>tbody>tr>td>div>a:hover {
	color: var(--main-color);
	background-color: transparent;
}

.location-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.info-details {
	display: flex;
	flex-direction: column;

}

.info-details h1 {
	font-size: 16px;
	font-weight: 700;
}

.info-details h3 {
	font-size: 20px;
	font-weight: 700;
	text-align: center;
}


.terms {
	height: 40vh;
	position: relative;
}

.terms img {
	height: 40vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	object-fit: cover;
}

.terms::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 40vh;
	background: rgba(17, 40, 72, 0.629);
	z-index: -1;
}

.terms .container {
	color: #fff;
	padding: 120px 0;
}

.terms h1 {
	font-size: 40px;
	font-weight: 600;
	color: var(--gold);
}

.terms span {
	font-size: 30px;
	font-weight: 400;
	color: var(--white);
}

.terms-heading {
	width: 60%;
	margin: auto;
	background-color: var(--whitesmoke);
	box-shadow: 0 0 15px 0 #72809d;
	padding: 30px 30px;
	border-radius: 20px;
}

.terms-heading h1 {
	text-align: center;
	color: var(--blue-black);
	font-weight: 600;
	text-transform: capitalize;
	font-size: 35px;
}

.terms-heading p {
	color: #72809d;
	font-size: 24px;
	text-align: justify;
}

.contact-info {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}



.term-conditions {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

input[type=radio] {
	border: 1px solid #d3d3d3;
	content: none;
	margin: 0;
	position: absolute;
}

.checkbox>input,
input[type=radio] {
	-webkit-appearance: none;
	appearance: none;
	border: 1px solid #d3d3d3;
	content: none;
	margin: 0;
	position: absolute;
	height: 20px;
	outline: none;
	width: 20px;
}

input[type=radio]:checked:before {
	border: 1px solid #d3d3d3;
	color: green !important;
	content: "\00A0\2713\00A0" !important;
	font-size: 21px;
	font-weight: bolder;
	position: absolute;
}

.terms-conditions {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 20px;
	gap: 10px;
}

.terms-conditions h3 {
	font-size: 30px;
	font-weight: 700;
	color: var(--brown)
}

.terms-conditions p {
	font-size: 18px;
	font-weight: 500;
}

.terms-conditions a {
	font-size: 16px;
	font-weight: 500;
	color: blue;
	background-color: transparent;
}

.terms-conditions a:hover {
	background-color: transparent;
}

.payment-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 20px;
	gap: 10px;
}

.payment-info h3 {
	font-size: 30px;
	font-weight: 700;
	color: var(--brown)
}

.payment-info p {
	font-size: 20px;
	font-weight: 500;
}

@media screen and (max-width: 800px) {
	.flex {
		flex-direction: column;
	}

	.flex h1 {
		text-align: center;
		font-size: 24px;
	}

	.grid4,
	.grid3,
	.grid5 {
		grid-template-columns: repeat(1, 1fr);
	}

	.heading {
		width: 100%;
	}

	.container {
		max-width: 90%;
	}

	.back::after,
	.back img,
	.back {
		height: 30vh;
	}

	.terms::after,
	.terms img,
	.terms {
		height: 30vh;
	}

	.hero-heading {
		margin: auto;
	}

	.hero-heading h1 {
		color: #2d3954;
		font-weight: 600;
		text-transform: capitalize;
		font-size: 20px;
	}

	.hero-heading p {
		color: #72809d;
		font-size: 20px;
	}

	.hidden-xs {
		display: none;
	}

	.btn5,
	.btn6 {
		width: 70%;
	}
}

@media screen and (max-width: 950px) {

	.flex {
		flex-direction: column;
	}

	.location-info {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.user-logout {
		display: none;
	}

	.company-logo img {
		width: 70%;
	}

	.company-name h2 {
		font-size: 20px;
		margin-left: -20px;
	}

	.company-name p {
		font-size: 14px;
		margin-left: -20px;
	}

	.btn5,
	.btn6 {
		width: 70%;
	}
}

@media screen and (max-width: 1200px) {
	.toggle-bar .fa-bars {

		font-size: 30px;
		color: white;
		display: flex;
		flex: 1;
		margin-left: 10rem;
	}

	.toast-message {
		width: 100%;
	}

	.contact-info {
		flex-direction: column;
		align-items: center;
	}

	.terms-heading {
		width: 60%;
		margin: auto;
	}

	.terms-heading h1 {
		font-size: 24px;
	}

	.terms-heading p {
		font-size: 18px;
	}

	.btn5,
	.btn6 {
		width: 55%;
		font-size: 16px;
		font-weight: 600;
	}

	.button {
		width: 100%;
	}

	.payment-info h3 {
		font-size: 20px;
		font-weight: 700;
	}

	.payment-info p {
		font-size: 16px;
		font-weight: 500;
	}

	.terms-conditions h3 {
		font-size: 20px;
		font-weight: 700;
	}

	.terms-conditions p {
		font-size: 16px;
		font-weight: 500;
	}

	.terms-conditions a {
		font-size: 14px;
		font-weight: 500;
	}
}

.table-wrapper {
	width: 100%;
	height: 300px;
	overflow: auto;
	display: inline-block;
}

th{
	top: 0;
	position: sticky;
	background: var(--brown);
	color: var(--white);
}