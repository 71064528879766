.footerContact {
  /* background-color: #27ae60; */
  background-color: var(--main-color);
  padding: 40px 0;
  color: #fff;
}

/* .footerContact h1 {
  font-size: 40px;
} */

.footer-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1d2636;
  padding: 50px 0;
  color: #fff;
}

.footer-main .container {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr 1fr;
  grid-gap: 20px;
}

.footer-main img {
  width: 150px;
  border-radius: 10%;
}

.footer-main h2 {
  font-weight: 700;
  text-align: center;
  padding: 2rem;
}

.footer-main p {
  text-align: center;
  color: rgb(231, 62, 62);
  margin: 20px 0;
  font-size: 18px;
  font-weight: 600;
}


.footer-main input {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  border-radius: 5px;
}

.footer-main h3 {
  font-weight: 500;
  margin-bottom: 30px;
}

.footer-main ul {
  display: block;
}

.footer-main ul li {
  display: block;
  color: grey;
  margin-bottom: 20px;
}

.legal {
  text-align: center;
  padding: 20px;
  background: var(--main-color);
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 800px) {
  .footer-main .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-main img {
    width: 70px;
    border-radius: 10%;
  }

  .footer-main h2 {
    font-size: 24px;
  }

  .footer-main p {
    font-size: 18px;
  }
}